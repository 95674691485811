import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import Styleswrapper from '../../components/StylesWrapper';
import PageMeta from '../../components/blocks/helmet/PageMeta';
import Mainheader from '../../components/MainHeader';
import { Link } from 'gatsby';
import WorkOutro from '../../components/work/WorkOutro';
import Footer from '../../components/Footer';
import { useLocation } from '@reach/router';
import { motion, AnimatePresence } from 'framer-motion';
import gsap from 'gsap/all';
import { StaticImage } from 'gatsby-plugin-image';

import '../../styles/work/index.css';

// images
import IndiaFlag from '../../images/work/flags/india.png';
import UsaFlag from '../../images/work/flags/usa.png';
import SlangbustersLogo from '../../images/work/logos/slangbusters.svg';
import ArrowRight from '../../images/icons/arrow_right.svg';
import AleyrLogo from '../../images/work/logos/aleyr.svg';
import CodezerosLogo from '../../images/work/logos/codezeros.svg';
import ConnektionLogo from '../../images/work/logos/connektion.svg';

const WorkIndex = () => {

    const [projectsType, setProjectsType] = useState('leadership');
    const [lastProjectsType, setLastProjectType] = useState('leadership');
    const location = useLocation();

    const projectsTypeRef = useRef();

    useEffect(() => {
        if (!location.hash) return;

        if (location.hash === '#leadership' || location.hash === '#branding' || location.hash === '#software') {
            setProjectsType(location.hash.split('#')[1]);

            projectsTypeRef.current.scrollIntoView({ behavior: 'smooth' });
        }

    }, [location.hash]);

    const handleWorkTypeLinkClick = (e) => {
        // set previous project type for animation
        setLastProjectType(projectsType);

        e.preventDefault();
        let projectTypeHash = e.target.href.split('#')[1];
        setProjectsType(projectTypeHash);
        projectsTypeRef.current.scrollIntoView({ behavior: 'smooth' });

        // set window location hash to the project type
        window.history.pushState(null, null, `#${projectTypeHash}`);
    }


    const introDurations = {
        transition: 1,
        stayFor: 4,
    }

    useLayoutEffect(() => {
        let underlineTl = gsap.timeline({
            repeat: -1,
            defaults: {
                duration: introDurations.transition,
                ease: "power4.out",
                stagger: {
                    each: introDurations.stayFor,
                },
            }
        });

        underlineTl.to(".work-home-intro h2 a", {
            textDecorationColor: "rgba(255,255,255,1)",
        }, 0)
            .to(".work-home-intro h2 a", {
                textDecorationColor: "rgba(255,255,255,0)",
            }, introDurations.stayFor - introDurations.transition);

        // cleanup
        return () => {
            underlineTl.kill();
        };
    }, [introDurations.transition, introDurations.stayFor]);

    useLayoutEffect(() => {
        let artBlockTl = gsap.timeline({
            repeat: -1,
            defaults: {
                ease: "power4.out",
                duration: introDurations.transition,
                stagger: {
                    each: introDurations.stayFor,
                },
            }
        });

        artBlockTl.to(".work-main-art-block", {
            opacity: 1,
            zIndex: 1,
        }, 0)
            .to(".work-main-art-block", {
                opacity: 0,
            }, introDurations.stayFor - introDurations.transition);

        // cleanup
        return () => {
            artBlockTl.kill();
        };
    }, [introDurations.transition, introDurations.stayFor]);

    const [activeBrandingLink, setActiveBrandingLink] = useState('aleyr');

    useEffect(() => {
        if (projectsType !== "branding") return;
        if (localStorage.getItem("workSwitched")) return;

        let timer = setTimeout(() => {
            setActiveBrandingLink('codezeros');
            localStorage.setItem("workSwitched", true);
        }, 2000);

        // cleanup
        return () => {
            clearTimeout(timer);
        };
    }, [projectsType]);

    return (
        <Styleswrapper
            bgColor1="#081D4D"
            bgColor2="#00070D"
            bg1Stop="0%"
            bg2Stop="35%"
            noise="true"
        >

            <PageMeta
                theme="#081D4D"
                title="Work — Nihar Bhagat"
                description="Evidence of my values translated into action"
                image="../images/social/work/default.jpg"
                twitterImage="../images/social/work/twitter.jpg"
            />

            <Mainheader
                bgColor1="#081D4D"
                bgColor2="#00070D"
                bg1Stop="0%"
                bg2Stop="35%"
                noise="true"
            />


            <section className="work-main">
                <div className="work-main-wrapper">
                    <div className="work-home-intro">
                        <h1>
                            Action is the evidence.
                        </h1>
                        <h2>
                            <a href="#leadership" onClick={(e) => { handleWorkTypeLinkClick(e) }}>I have led a Design Studio<span className="progress"></span></a>,<br />
                            <a href="#branding" onClick={(e) => { handleWorkTypeLinkClick(e) }}>created long-lasting Brands<span className="progress"></span></a> and <br /><a href="#software" onClick={(e) => { handleWorkTypeLinkClick(e) }}>digital Products from concept to reality.<span className="progress"></span></a>
                        </h2>
                        <p>
                            <span>
                                I help in <span className="heavy">fully</span> understanding the context,<br />formulate relevant strategies, and take action.
                            </span>
                        </p>
                    </div>
                    <div className="work-main-art">
                        <div className="work-main-art-block">
                            <StaticImage
                                src="../../images/work/1.jpg"
                                width={700}
                                alt="Two people delighted while having a conversation"
                                loading="lazy"
                                draggable="false"
                                placeholder="blurred"
                            />
                        </div>
                        <div className="work-main-art-block">
                            <StaticImage
                                src="../../images/work/2.jpg"
                                width={700}
                                alt="Hand on a diary next to a note saying remember why you started"
                                loading="lazy"
                                draggable="false"
                                placeholder="blurred"
                            />
                        </div>
                        <div className="work-main-art-block">
                            <StaticImage
                                src="../../images/work/3.jpg"
                                width={700}
                                alt="A wall clock with research notes around it on a wall"
                                loading="lazy"
                                draggable="false"
                                placeholder="blurred"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="wh-projects-type" ref={projectsTypeRef}>
                <nav className="wh-sub-nav">
                    <ul>
                        <li>
                            <a href="#leadership"
                                onClick={(e) => { handleWorkTypeLinkClick(e) }}
                                className={projectsType === 'leadership' ? 'active-link' : ''}>Leadership</a>
                        </li>
                        <li>
                            <a href="#branding"
                                onClick={(e) => { handleWorkTypeLinkClick(e) }}
                                className={projectsType === 'branding' ? 'active-link' : ''}>Branding</a>
                        </li>
                        <li>
                            <a href="#software"
                                onClick={(e) => { handleWorkTypeLinkClick(e) }}
                                className={projectsType === 'software' ? 'active-link' : ''}>Software</a>
                        </li>
                    </ul>
                </nav>

                {/* leadership */}
                <AnimatePresence>
                    {projectsType === 'leadership' && (
                        <motion.div
                            className="wh-projects-wrapper"
                            id="leadership"
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0, transition: { duration: 0.2, delay: 0.1, ease: "circOut" } }}
                            exit={{ opacity: 0, x: -10, transition: { duration: 0.1 } }}
                        >
                            <div className="wh-projects">
                                <div className="whp-details">
                                    <Link to="/work/slangbusters" className='whp-link whp-link-active slangbusters'>
                                        <div className="primary">
                                            <div className="whp-link-head">
                                                <div className="chips">
                                                    <p className="chip">
                                                        Branding Studio
                                                    </p>
                                                    <p className="chip">
                                                        Creative Director
                                                    </p>
                                                </div>
                                                <div className="location">
                                                    <span>Gujarat, India</span>
                                                    <img src={IndiaFlag}
                                                        alt=""
                                                        loading='eager'
                                                        draggable='false'
                                                    />
                                                </div>
                                            </div>
                                            <div className="whp-link-logo">
                                                <img src={SlangbustersLogo}
                                                    alt="Slangbusters"
                                                    loading='eager'
                                                    draggable='false'
                                                />
                                            </div>
                                            <p className="whp-link-title">
                                                The most heartful Brand we made at Slangbusters was Slangbusters itself
                                            </p>
                                        </div>
                                        <div className="secondary">
                                            <div className="whp-link-progress">
                                                <div className="progress-bar"></div>
                                                <span>View Case Study</span>
                                                <img src={ArrowRight} alt="→" loading='eager' draggable='false' />
                                            </div>
                                            <div className="whp-link-review">
                                                <p className='review-text'>
                                                    "...has a good sense of articulating the brand philosophy online..."
                                                </p>
                                                <div className="review-author">
                                                    <StaticImage
                                                        src="../../images/work/kiran.jpg"
                                                        width={60}
                                                        quality={100}
                                                        alt="Kiran Singh"
                                                        loading="eager"
                                                        draggable="false"
                                                        placeholder="blurred"
                                                    />
                                                    <p className="name">
                                                        Kiran Singh, CEO, Barunii Beauty
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="whp-art">
                                    <StaticImage
                                        src="../../images/work/slangbusters.jpg"
                                        width={600}
                                        quality={100}
                                        alt="Three people around a desk discussing a project"
                                        loading="lazy"
                                        draggable="false"
                                        placeholder="blurred"
                                    />
                                </div>
                            </div>
                            <WorkOutro type={projectsType} referrerPath={"work-" + projectsType} />
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* branding */}
                <AnimatePresence>
                    {projectsType === 'branding' && (
                        <motion.div
                            className="wh-projects-wrapper"
                            id="branding"
                            initial={{ opacity: 0, x: lastProjectsType === 'leadership' ? 10 : -10 }}
                            animate={{ opacity: 1, x: 0, transition: { duration: 0.2, delay: 0.1, ease: "circOut" } }}
                            exit={{ opacity: 0, x: lastProjectsType === 'leadership' ? 10 : -10, transition: { duration: 0.1 } }}
                        >
                            <div className="wh-projects">
                                <div className="whp-details">
                                    <Link
                                        to="/work/aleyr"
                                        className={`whp-link aleyr ${activeBrandingLink === 'aleyr' ? 'whp-link-active' : ''}`}
                                        onMouseEnter={() => { setActiveBrandingLink('aleyr') }}
                                    >
                                        <div className="primary">
                                            <div className="whp-link-head">
                                                <div className="chips">
                                                    <p className="chip">
                                                        Pet Care Company
                                                    </p>
                                                    <p className="chip">
                                                        Creative Director
                                                    </p>
                                                </div>
                                                <div className="location">
                                                    <span>Florida, USA</span>
                                                    <img src={UsaFlag}
                                                        alt=""
                                                        loading='eager'
                                                        draggable='false'
                                                    />
                                                </div>
                                            </div>
                                            <div className="whp-link-logo">
                                                <img src={AleyrLogo}
                                                    alt="Aleyr"
                                                    loading='eager'
                                                    draggable='false'
                                                />
                                            </div>
                                            <p className="whp-link-title">
                                                Discovering an entirely new position in a highly saturated market
                                            </p>
                                        </div>
                                        <div className="secondary">
                                            <div className="whp-link-progress">
                                                <div className="progress-bar"></div>
                                                <span>View Case Study</span>
                                                <img src={ArrowRight} alt="→" loading='eager' draggable='false' />
                                            </div>
                                            <div className="whp-link-review">
                                                <p className='review-text'>
                                                    "...the most innovative, dedicated and motivated bunch of people..."
                                                </p>
                                                <div className="review-author">
                                                    <StaticImage
                                                        src="../../images/work/smit.jpg"
                                                        width={60}
                                                        quality={100}
                                                        alt="Smit Chokshi"
                                                        loading="eager"
                                                        draggable="false"
                                                        placeholder="blurred"
                                                    />
                                                    <p className="name">
                                                        Smit Chokshi, COO, Aleyr
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>

                                    <Link
                                        to="/work/codezeros"
                                        className={`whp-link codezeros ${activeBrandingLink === 'codezeros' ? 'whp-link-active' : ''}`}
                                        onMouseEnter={() => { setActiveBrandingLink('codezeros') }}
                                    >
                                        <div className="primary">
                                            <div className="whp-link-head">
                                                <div className="chips">
                                                    <p className="chip">
                                                        Emerging Tech<span>nology</span> Company
                                                    </p>
                                                    <p className="chip">
                                                        Chief Branding Officer
                                                    </p>
                                                </div>
                                                <div className="location">
                                                    <span>Gujarat, India</span>
                                                    <img src={IndiaFlag}
                                                        alt=""
                                                        loading='eager'
                                                        draggable='false'
                                                    />
                                                </div>
                                            </div>
                                            <div className="whp-link-logo">
                                                <img src={CodezerosLogo}
                                                    alt="Codezeros"
                                                    loading='eager'
                                                    draggable='false'
                                                />
                                            </div>
                                            <p className="whp-link-title">
                                                Creating a Web3 company from inside-out during a time of outside-in
                                            </p>
                                        </div>
                                        <div className="secondary">
                                            <div className="whp-link-progress">
                                                <div className="progress-bar"></div>
                                                <span>View Case Study</span>
                                                <img src={ArrowRight} alt="→" loading='eager' draggable='false' />
                                            </div>
                                            <div className="whp-link-review">
                                                <p className='review-text'>
                                                    "...helped build a brand so good, it seems like science fiction..."
                                                </p>
                                                <div className="review-author">
                                                    <StaticImage
                                                        src="../../images/work/deep.jpg"
                                                        width={60}
                                                        quality={100}
                                                        alt="Deep Shah"
                                                        loading="eager"
                                                        draggable="false"
                                                        placeholder="blurred"
                                                    />
                                                    <p className="name">
                                                        Deep Shah, CEO, Codezeros
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <AnimatePresence>
                                    {activeBrandingLink === 'aleyr' && (
                                        <motion.div
                                            className="whp-art"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1, transition: { duration: 0.1, delay: 0.1 } }}
                                            exit={{ opacity: 0, transition: { duration: 0.1 } }}
                                        >
                                            <StaticImage
                                                src="../../images/work/aleyr.jpg"
                                                width={700}
                                                quality={100}
                                                alt="A doggie in an Aleyr pet store"
                                                loading="lazy"
                                                draggable="false"
                                                placeholder="blurred"
                                            />
                                        </motion.div>
                                    )}

                                    {activeBrandingLink === 'codezeros' && (
                                        <motion.div
                                            className="whp-art whp-art-codezeros"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1, transition: { duration: 0.1, delay: 0.1 } }}
                                            exit={{ opacity: 0, transition: { duration: 0.1 } }}
                                        >
                                            <StaticImage
                                                src="../../images/work/codezeros.jpg"
                                                width={700}
                                                quality={100}
                                                alt="Signage template on the office door around a scratched plastic sheet"
                                                loading="lazy"
                                                draggable="false"
                                                placeholder="blurred"
                                            />
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                            <WorkOutro type={projectsType} referrerPath={"work-" + projectsType} />
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* software */}
                <AnimatePresence>
                    {projectsType === 'software' && (
                        <motion.div
                            className="wh-projects-wrapper"
                            id="software"
                            initial={{ opacity: 0, x: 10 }}
                            animate={{ opacity: 1, x: 0, transition: { duration: 0.2, delay: 0.1, ease: "circOut" } }}
                            exit={{ opacity: 0, x: 10, transition: { duration: 0.1 } }}
                        >
                            <div className="wh-projects">
                                <div className="whp-details">
                                    <Link to="/work/connektion" className='whp-link whp-link-active connektion'
                                    >
                                        <div className="primary">
                                            <div className="whp-link-head">
                                                <div className="chips">
                                                    <p className="chip">
                                                        Job Management System
                                                    </p>
                                                    <p className="chip">
                                                        Product Designer
                                                    </p>
                                                </div>
                                                <div className="location">
                                                    <span>New York, USA</span>
                                                    <img src={UsaFlag}
                                                        alt=""
                                                        loading='eager'
                                                        draggable='false'
                                                    />
                                                </div>
                                            </div>
                                            <div className="whp-link-logo">
                                                <img src={ConnektionLogo}
                                                    alt="Connektion"
                                                    loading='eager'
                                                    draggable='false'
                                                />
                                            </div>
                                            <p className="whp-link-title">
                                                Designing a 3 part platform system to help students get their first job
                                            </p>
                                        </div>
                                        <div className="secondary">
                                            <div className="whp-link-progress">
                                                <div className="progress-bar"></div>
                                                <span>View Case Study</span>
                                                <img src={ArrowRight} alt="→" loading='eager' draggable='false' />
                                            </div>
                                            <div className="whp-link-review">
                                                <p className='review-text'>
                                                    "...very thought-out and thorough, with intelligence and design sense..."
                                                </p>
                                                <div className="review-author">
                                                    <StaticImage
                                                        src="../../images/work/pooja.jpg"
                                                        width={60}
                                                        quality={100}
                                                        alt="Pooja Patel"
                                                        loading="eager"
                                                        draggable="false"
                                                        placeholder="blurred"
                                                    />
                                                    <p className="name">
                                                        Pooja Patel, COO, Connektion Careers
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>

                                    <Link to="#" className='whp-link ui-showcase disabled' tabIndex={-1} onClick={(e) => {e.preventDefault()}}>
                                        <div className="primary">
                                            <p className="whp-link-title">
                                                UI Showcase
                                            </p>
                                        </div>
                                        <div className="secondary">
                                            <div className="whp-link-progress">
                                                <div className="progress-bar"></div>
                                                <span>Browse</span>
                                                <img src={ArrowRight} alt="→" loading='eager' draggable='false' />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="whp-art">
                                    <StaticImage
                                        src="../../images/work/connektion.jpg"
                                        width={1400}
                                        quality={100}
                                        alt="Sheets of user interview notes"
                                        loading="lazy"
                                        draggable="false"
                                        placeholder="blurred"
                                    />
                                </div>
                            </div>
                            <WorkOutro type={projectsType} referrerPath={"work-" + projectsType} />
                        </motion.div>
                    )}
                </AnimatePresence>
            </section>

            <Footer
                furtherReadingLinks={true}
            />

        </Styleswrapper >
    );
}

export default WorkIndex;
